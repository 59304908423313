module.exports = [{
      plugin: require('/Users/kevin/Documents/GitHub/credit101-react/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://credit101.net","stripQueryString":true},
    },{
      plugin: require('/Users/kevin/Documents/GitHub/credit101-react/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-119874522-3"},
    },{
      plugin: require('/Users/kevin/Documents/GitHub/credit101-react/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js"}},
    },{
      plugin: require('/Users/kevin/Documents/GitHub/credit101-react/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kevin/Documents/GitHub/credit101-react/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
