// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-build-index-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/build/index.js" /* webpackChunkName: "component---src-pages-build-index-js" */),
  "component---src-pages-cards-index-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/cards/index.js" /* webpackChunkName: "component---src-pages-cards-index-js" */),
  "component---src-pages-contact-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-score-index-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/score/index.js" /* webpackChunkName: "component---src-pages-score-index-js" */),
  "component---src-pages-tos-js": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-build-proven-ways-to-build-credit-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/build/proven-ways-to-build-credit.mdx" /* webpackChunkName: "component---src-pages-build-proven-ways-to-build-credit-mdx" */),
  "component---src-pages-build-you-need-good-credit-but-why-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/build/you-need-good-credit-but-why.mdx" /* webpackChunkName: "component---src-pages-build-you-need-good-credit-but-why-mdx" */),
  "component---src-pages-cards-best-credit-cards-for-college-students-in-2019-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/cards/best-credit-cards-for-college-students-in-2019.mdx" /* webpackChunkName: "component---src-pages-cards-best-credit-cards-for-college-students-in-2019-mdx" */),
  "component---src-pages-cards-credit-card-glossary-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/cards/credit-card-glossary.mdx" /* webpackChunkName: "component---src-pages-cards-credit-card-glossary-mdx" */),
  "component---src-pages-cards-getting-your-first-credit-card-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/cards/getting-your-first-credit-card.mdx" /* webpackChunkName: "component---src-pages-cards-getting-your-first-credit-card-mdx" */),
  "component---src-pages-cards-unboxing-amex-rose-gold-card-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/cards/unboxing-amex-rose-gold-card.mdx" /* webpackChunkName: "component---src-pages-cards-unboxing-amex-rose-gold-card-mdx" */),
  "component---src-pages-cards-what-is-a-charge-card-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/cards/what-is-a-charge-card.mdx" /* webpackChunkName: "component---src-pages-cards-what-is-a-charge-card-mdx" */),
  "component---src-pages-score-credit-score-glossary-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/score/credit-score-glossary.mdx" /* webpackChunkName: "component---src-pages-score-credit-score-glossary-mdx" */),
  "component---src-pages-score-four-ways-to-check-your-credit-score-mdx": () => import("/Users/kevin/Documents/GitHub/credit101-react/src/pages/score/four-ways-to-check-your-credit-score.mdx" /* webpackChunkName: "component---src-pages-score-four-ways-to-check-your-credit-score-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/kevin/Documents/GitHub/credit101-react/.cache/data.json")

